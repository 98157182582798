import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import axios from 'axios';

export default function Keypointchart(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  async function getData() {
    await axios(process.env.REACT_APP_DATA_SRV + '/' + props.system + '/case/' + 
      props.case_id + '/' + props.excercise_id + '/' + props.label)
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) return "Loading...";
  if (error) return "Error!";
  
  return (
    <div>
      <div className="container">
        <h3>{data['name']}</h3>
        <LineChart
          width={1000}
          height={200}
          data={data['list']}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
        <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="x" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="y" stroke="#8ff4d8" activeDot={{ r: 8 }} />
          {('z' in data['list'][0]) &&
              <Line type="monotone" dataKey="z" stroke="#aa344f" activeDot={{ r: 8 }} />
          }
        </LineChart>
      </div>
    </div>
  );
}
