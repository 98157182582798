import React, { useState, useEffect } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import Datatable from './Datatable'

export default function Radarchart(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  async function getData() {
    await axios(process.env.REACT_APP_DATA_SRV + '/calc/' + 
      props.case_id + '/' + props.excercise_id + '/' + props.label)
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) return "Loading radar chart...";
  if (error) return "Error!";
  
  return (
    <div className="container">
      
          <RadarChart
            cx={300}
            cy={250}
            outerRadius={150}
            width={500}
            height={500}
            data={data}
          >
          <PolarGrid />
          <PolarAngleAxis dataKey="label" />
          <PolarRadiusAxis angle={0} domain={[0, 1]}/>
          <Radar name="Pearson correlation" dataKey="corr" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        </RadarChart>
        <Datatable data={data}/>
    </div>
  );
}
