import React, { useState, useEffect } from 'react';
import Keypointchart from './Keypointchart';
import axios from 'axios';

export default function C3Dcharts(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [labels, setLabels] = useState(null)

  async function getData() {
    await axios(process.env.REACT_APP_DATA_SRV + "/items/keypoints")
    .then((response) => {
      setLabels(response.data['keypoints'][props.label])
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) return "Loading...";
  if (error) return "Error!";
  
  return (
    <div>
      {
        labels.map(function(item, i){
          return <Keypointchart
            key={i}
            system="c3d"
            case_id={props.case_id}
            excercise_id={props.excercise_id}
            label={item}/>
      })}
    </div>
  );
}
