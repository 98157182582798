import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Controlform(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [state, setState] = useState({});
  const [person, setPerson] = useState("D");
  const [exercise, setExercise] = useState("zit1");

  const handleToggle = ({ target }) =>
    setState(s => ({ ...s, [target.name]: !s[target.name] }));
    props.parentLabelCallback(state);

  const handleChangePerson = ({ target }) =>
    setPerson([target.value][0]);
    props.parentPersonCallback(person);
    
  const handleChangeExercise = ({ target }) =>
    setExercise([target.value][0]);
    props.parentExerciseCallback(exercise);

  async function getData() {
    await axios(process.env.REACT_APP_DATA_SRV + "/items/keypoints")
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) return "Loading...";
  if (error) return "Error!";
  
  return (
    <div>
      <form>
        <div className="container">
          <label>
            Testpersoon:
            <select value={person.value} onChange={handleChangePerson}>
              <option value="D">Persoon D</option>
              <option value="E">Persoon E</option>
              <option value="F">Persoon F</option>
              <option value="G">Persoon G</option>
              <option value="H">Persoon H</option>
              <option value="I">Persoon I</option>
              <option value="J">Persoon J</option>
            </select>
          </label>
        </div>
        <div className="container">
          <label>
            Beweging:
            <select value={exercise.value} onChange={handleChangeExercise}>
              <option value="zit1">Zit 1</option>
              <option value="zit2">Zit 2</option>
              <option value="zit3">Zit 3</option>
              <option value="zit4">Zit 4</option>
              <option value="zit5">Zit 5</option>
              <option value="arm1">Arm 1</option>
              <option value="arm2">Arm 2</option>
              <option value="arm3">Arm 3</option>
              <option value="arm4">Arm 4</option>
              <option value="arm5">Arm 5</option>
              <option value="raap1">Raap 1</option>
              <option value="raap2">Raap 2</option>
              <option value="raap3">Raap 3</option>
              <option value="raap4">Raap 4</option>
              <option value="raap5">Raap 5</option>
            </select>
          </label>
        </div>
      {Object.entries(data['keypoints']).map(([key, value]) => (
        <div className="container">
        <label>
          {key}:
          <input
            type="checkbox"
            onChange={handleToggle}
            key={key}
            name={key}
            checked={state[key]}
          />
        </label>
        </div>
      ))}
      </form>
    </div>
  );
}