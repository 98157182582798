import React, { useState } from 'react';
import Keypointchart from './Keypointchart';
import Radarchart from './Radarchart';
import Controlform from './Controlform';
import C3Dcharts from './C3Dcharts';

export default function Dashpage() {
  const [label, setLabel] = useState({});
  const [person, setPerson] = useState({});
  const [exercise, setExercise] = useState({});

  function callbackLabel(childData){
    for (var key in childData) {
      if(childData[key] === false){
        delete childData[key]
      }
    }
    setLabel(childData)
  }

  function callbackPerson(childData){
    setPerson(childData)
  }

  function callbackExercise(childData){
    setExercise(childData)
  }
  

  return (
    <div>
      <Controlform parentLabelCallback={callbackLabel}
      parentPersonCallback={callbackPerson}
      parentExerciseCallback={callbackExercise} />
      {Object.entries(label).map(([key, value]) => (        
          <div>
            <Radarchart
              case_id={person}
              excercise_id={exercise}
              label={key}/>
            <Keypointchart 
              system="idbps"
              case_id={person}
              excercise_id={exercise}
              label={key}/>
            <C3Dcharts 
              case_id={person}
              excercise_id={exercise}
              label={key} />
          </div>
        ))}
    </div>
  );
}
