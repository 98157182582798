import React, { useState, useEffect } from 'react';

export default function Datatable(props) {

  return (
    <>
      <h1>Pearson Correlation</h1>
      <p>The VICON keypoints in relation to iDBPS:</p>
      AVG: {(Math.round(((props.data.reduce((a,v) =>  a = a + v.corr , 0 )) / props.data.length)*1000)/1000)}
      <ul>
      {

        props.data.map(function(item){
          // returns Nathan, then John, then Jane
          return <li> {item.label} with corr. {item.corr} and p-value: {item.p_value}  </li>
        })
      }
      </ul>
    </>
  )
}
