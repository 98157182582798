import './App.css';
import Dashpage from './Dashpage';

function App() {
  console.log(process.env.REACT_APP_DATA_SRV);
  return (
    <div className="App">
        <Dashpage />
    </div>
  );
}

export default App;
